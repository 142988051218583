import axios from "axios";
import { ROOT_URL } from "../constants";
import { wrapToJWT } from "./wrapToJWT";

const instance = wrapToJWT(
  axios.create({
    baseURL: ROOT_URL + "/action.php",
  })
);

export const walletAPI = {
  topUp({ id, amount }) {
    return instance
      .post("/", {
        action: "Pay",
        data: { id, amount },
      })
      .then((response) => response.data);
  },
};

export const payTbank = ({ id, users, sum }) => {
  return instance
    .post("/", {
      action: "payForRegistration",
      data: { id: id, users: users, sum: sum },
    })
    .then((response) => {
      if (response.data.ok) {
        window.location.replace(response.data.data.paymentUrl);
      }
      console.log(response.data);
    });
};
