import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, Paper, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { Add, Mail, Refresh } from "@mui/icons-material";
import DataTable from "../DataTable/DataTable";
import s from "./Participants.module.sass";
import ConfirmUserContainer from "../ConfirmUser/ConfirmUserContainer";
import { gr } from "../../AppExtComponents.ts";
import { StatusLine } from "../StatusLine/StatusLine";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ControlledModal from "../ControlledModal/ControlledModal";
import Loading from "../Loading/Loading";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { getCookie } from "../../AppExtComponents.ts";
import ParticipantsFilter from "../filters/ParticipantsFilter";
import RegistrationPayment from "../RegistrationPayment/RegistrationPayment";
import BlockIcon from "@mui/icons-material/Block";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "../../language/lang";
import { VirtualizedTable } from "../VirtualizedTable/VirtualizedTable";

const Participants = ({
  headings,
  rows,
  columns,
  loadMore,
  refreshTable,
  currentRow,
  isPaymentModalOpen,
  handleClosePaymentModal,
  isConfirmModalOpen,
  setConfirmModalOpen,
  isFetching,
  isDeleteUserModalOpen,
  handleCloseUserDeleteModal,
  deleteUser,
  defaultConfirmSettings,
  updateSettings,
  areDocumentsRequired,
  isFree,
  handleCloseEditUserModal,
  isEditUserModalOpen,
  editUser,
  disciplinesNames,
  amounts,
  usersFileUrl,
  downloadFile,
  confirmBaskets,
  setCurrentRow,
}) => {
  const ConfirmationTrigger = {
    REGISTRATION: 1,
    DOCS: 2,
    WEIGHTING: 3,
    PAYMENT: 4,
  };
  const defaultSettings = useRef(defaultConfirmSettings);
  const [settings, setSettings] = useState(defaultConfirmSettings);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isArrayChanged({ prev: settings, current: defaultConfirmSettings })) {
      setSettings(defaultConfirmSettings);
      defaultSettings.current = defaultConfirmSettings;
    }
  }, [defaultConfirmSettings]);

  useEffect(() => {
    return () => {
      defaultSettings.current = [];
      setSettings([]);
    };
  }, []);

  const updateConfirmationSystem = (trigger) => {
    return (e) => {
      if (e.target.checked) {
        setSettings([
          ...new Set([...settings, trigger, ConfirmationTrigger.REGISTRATION]),
        ]);
      } else {
        const newSettings = [...settings];
        newSettings.splice(newSettings.indexOf(trigger), 1);
        setSettings(newSettings);
      }
    };
  };

  const saveSettings = () => {
    const sortedSettings = settings.sort((a, b) => a - b);
    updateSettings(sortedSettings);
    setSettings(sortedSettings);
    defaultSettings.current = sortedSettings;
  };

  const isArrayChanged = ({ prev, current }) => {
    if (prev.length === current.length) {
      const existance = prev.map((el) => current.includes(el));
      if (!existance.includes(false)) return false;
    }
    return true;
  };

  const handleEditUser = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    const weight = data.weight;
    delete data.weight;
    const checkedDisciplines = Object.keys(data);
    if (checkedDisciplines.length === 0) {
      enqueueSnackbar("Выберите дисциплины", { variant: "warning" });
    } else {
      editUser({
        weight: +weight,
        disciplines: checkedDisciplines,
      });
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
    setTimeout(() => setCurrentRow({}), 100);
  };

  return (
    <>
      <Loading
        isLoading={isFetching.event.gettingParticipants || isFetching.settings}
      />

      <div className={s.structure}>
        <div className={`${s.filter} ${s.filter_desktop}`}>
          <ParticipantsFilter isExpanded />
        </div>

        <div className={`${s.filter} ${s.filter_mobile}`}>
          <ParticipantsFilter />
        </div>

        <div className={s.wrapper}>
          <div className={s.statuses}>
            <StatusLine
              customIcon={<BlockIcon sx={{ fill: "red" }} />}
              text={Text.Participants.Status.Rejected}
            />
            <StatusLine
              customIcon={<CreditScoreIcon sx={{ fill: "#dfcb11" }} />}
              text={Text.Participants.Status.Paid}
            />
            <StatusLine
              customIcon={<CheckCircleIcon sx={{ fill: "green" }} />}
              text={Text.Participants.Status.Weighted}
            />
            <StatusLine
              customIcon={<CurrencyRubleIcon sx={{ fill: "gray" }} />}
              text={Text.Participants.Status.NotPaid}
            />
          </div>

          {getCookie("admin") && (
            <div>
              <br />
              <div className={s.manage}>
                <LoadingButton
                  loading={isFetching.basket}
                  variant="outlined"
                  color="secondary"
                  onClick={confirmBaskets}
                >
                  Утвердить сетки
                </LoadingButton>
                <LoadingButton
                  loading={isFetching.event.file}
                  variant="outlined"
                  color="secondary"
                  onClick={downloadFile}
                >
                  Скачать список участников
                </LoadingButton>
              </div>
              <div>
                <br />
                <h4>После чего спортсмены будут добавлены в списки:</h4>
                <CustomCheckbox
                  label={"Регистрация"}
                  onChange={updateConfirmationSystem(
                    ConfirmationTrigger.REGISTRATION
                  )}
                  checked={settings.includes(ConfirmationTrigger.REGISTRATION)}
                  disabled={
                    settings.includes(ConfirmationTrigger.DOCS) ||
                    settings.includes(ConfirmationTrigger.WEIGHTING) ||
                    settings.includes(ConfirmationTrigger.PAYMENT)
                  }
                />
                {areDocumentsRequired && (
                  <CustomCheckbox
                    label={"Проверка документов"}
                    onChange={updateConfirmationSystem(
                      ConfirmationTrigger.DOCS
                    )}
                    checked={settings.includes(ConfirmationTrigger.DOCS)}
                  />
                )}
                <CustomCheckbox
                  label={"Взвешивание"}
                  onChange={updateConfirmationSystem(
                    ConfirmationTrigger.WEIGHTING
                  )}
                  checked={settings.includes(ConfirmationTrigger.WEIGHTING)}
                />
                {!isFree && (
                  <CustomCheckbox
                    label={"Оплата"}
                    onChange={updateConfirmationSystem(
                      ConfirmationTrigger.PAYMENT
                    )}
                    checked={settings.includes(ConfirmationTrigger.PAYMENT)}
                  />
                )}
                {isArrayChanged({
                  prev: defaultSettings.current,
                  current: settings,
                }) && (
                  <Button
                    endIcon={<CheckIcon />}
                    color="secondary"
                    onClick={saveSettings}
                  >
                    Сохранить
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className={s.counts}>
            <span>
              {Text.Participants.Count.NumberOfParticipants}:{" "}
              <span className={s.counts__number}>{amounts.participants}</span>
            </span>
            <span>
              {Text.Participants.Count.NumberOfAthletes}:{" "}
              <span className={s.counts__number}>{amounts.users}</span>
            </span>
            <span>
              {Text.Participants.Count.NumberOfClubs}:{" "}
              <span className={s.counts__number}>{amounts.clubs}</span>
            </span>
            <span>
              {Text.Participants.Count.NumberOfCountries}:{" "}
              <span className={s.counts__number}>{amounts.countries}</span>
            </span>
          </div>

          <Paper className={s.actions}>
            <LoadingButton onClick={refreshTable}>
              <Refresh />
            </LoadingButton>
          </Paper>
          {headings && rows && (
            <Box sx={{ maxWidth: "69vw" }}>
              <VirtualizedTable
                columns={headings}
                data={rows}
                tableRowClassName={s.tableRow}
                maxHeight={"800px"}
                isStickyHeader={true}
                tableHeight={400}
                itemSize={disciplinesNames.length * 17}
              />
            </Box>
          )}
          <ControlledModal
            isOpen={isConfirmModalOpen}
            onClose={handleCloseConfirmModal}
            title={<h3>{Text.Payment.Payment}</h3>}
          >
            <ConfirmUserContainer userData={currentRow} />
          </ControlledModal>

          <ControlledModal
            isOpen={isDeleteUserModalOpen}
            onClose={handleCloseUserDeleteModal}
            title={<h3>Удалить участника?</h3>}
          >
            <Loading isLoading={isFetching.event.deletionUser.status} />
            Участник: {currentRow.fio}
            <div className={s.finishButtons}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseUserDeleteModal}
              >
                Отмена
              </Button>
              <Button variant="contained" onClick={deleteUser}>
                Удалить
              </Button>
            </div>
          </ControlledModal>

          <ControlledModal
            isOpen={isEditUserModalOpen}
            onClose={handleCloseEditUserModal}
            title={<h3>Редактирование участника</h3>}
          >
            <Loading isLoading={isFetching.event.updationUser.status} />
            <form className={s.editUser} onSubmit={handleEditUser}>
              Участник: {currentRow.fio}
              <div className={s.editUser__disciplines}>
                {disciplinesNames.map((discipline, i) => (
                  <CustomCheckbox
                    key={discipline}
                    defaultChecked={currentRow.disciplines?.includes(
                      discipline
                    )}
                    name={discipline}
                    label={discipline}
                  />
                ))}
              </div>
              <TextField
                label={"Вес"}
                defaultValue={currentRow.weight}
                name="weight"
              />
              <Button variant="contained" type="submit">
                Сохранить
              </Button>
            </form>
          </ControlledModal>

          <ControlledModal
            isOpen={isPaymentModalOpen}
            onClose={handleClosePaymentModal}
            title={<h3>{Text.Payment.Payment}</h3>}
          >
            <RegistrationPayment />
          </ControlledModal>
        </div>
      </div>
    </>
  );
};

export default Participants;
