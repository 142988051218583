import ShowNearWeight from "./ShowNearWeight";
import { IconButton, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useState, useEffect, memo } from "react";
import { eventAPI } from "../../api/eventAPI";
import { updateEventUser } from "../../redux/reducers/event/eventThunks.ts";
import { useDispatch, useSelector } from "react-redux";
import { getEventState } from "../../redux/selectors/getEventState";
import {
  resetFetching,
  updateWeight,
} from "../../redux/reducers/event/event.ts";
import { getReponse } from "../../redux/reducers/snacks.ts";
export default (function WeightParticipant({ eventId, rowData }) {
  const [labelweight, setLabelWeight] = useState(0);
  const [changeData, setChangeData] = useState(0);
  const dispatch = useDispatch();
  const state = useSelector(getEventState);
  const statusNumber = 4;
  // useEffect(() => {
  //   if (isFetching.updateUserStatus.userID === rowData.id) {
  //     if (isFetching.updateUserStatus.proceed) {
  //       // dispatch(
  //       //   updateWeight({
  //       //     userID: rowData.id,
  //       //     fields: {
  //       //       status: Number(localStorage.getItem("memberStatus")),
  //       //       weight: Number(localStorage.getItem("weightAmount")),
  //       //     },
  //       //   })
  //       // );
  //       setLabelWeight(localStorage.getItem("weightAmount"));
  //       // dispatch(resetFetching());
  //       localStorage.removeItem("weightAmount");
  //       localStorage.removeItem("memberStatus");
  //     }
  //   }
  // }, [isFetching]);
  useEffect(() => {
    const eventUserId = localStorage.getItem(
      "eventUserId",
      eventId + rowData.id
    );
    if (eventUserId && eventUserId == eventId + rowData.id) {
      console.log(eventUserId);
      setLabelWeight(localStorage.getItem("weightAmount", labelweight));
      setChangeData(localStorage.getItem("weightAmount", labelweight));
      // localStorage.removeItem("eventUserId");
    } else {
      setLabelWeight(rowData.weight);
      setChangeData(rowData.weight);
    }
  }, []);
  const handleSaveWeight = () => {
    // const object = {
    //   id: eventId,
    //   user_id: rowData.id,
    //   status: 4,
    //   weight: labelweight,
    // };
    try {
      console.log(getReponse);
      dispatch(
        updateEventUser({
          id: eventId,
          user_id: rowData.id,
          status: statusNumber,
          weight: labelweight,
        })
      );
      localStorage.setItem("eventUserId", eventId + rowData.id);
      localStorage.setItem("weightAmount", labelweight);
      localStorage.setItem("memberStatus", statusNumber);
      // eventAPI.updateEventUser(object).then((r) => {
      //   console.log(r);
      //   if (r.ok) setChangeData(labelweight);
      // });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
      {" "}
      <ShowNearWeight
        eventId={eventId}
        id={rowData.id}
        weight={changeData}
      />{" "}
      <div style={{ display: "flex" }}>
        <TextField
          sx={{
            " input": {
              backgroundColor:
                labelweight === changeData ? "#00800060" : "rgba(0,0,0,0)",
              width: "45px",
            },
          }}
          id="standard-basic"
          type="number"
          variant="standard"
          label={"кг"}
          value={labelweight}
          onChange={(event) => setLabelWeight(event.target.value)}
        />
        <IconButton onClick={handleSaveWeight}>
          <DoneIcon />
        </IconButton>
      </div>
    </div>
  );
});
