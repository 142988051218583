// * === base
import React, { useRef, useEffect, useState } from "react";
import s from "./UserEdit.module.sass";
// * === ui
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useSnackbar } from "notistack";
import _ from "underscore";
import { CountrySuggestion } from "../../components/AddressSuggestion/CountrySuggestion";
import { CitySuggestion } from "../../components/AddressSuggestion/CitySuggestion";
import { DatePickerAuto } from "../../components/DatePickerAuto/DatePickerAuto";
import { Belts, ROOT_URL, SportsCategories } from "../../constants";
import { Avatar } from "./Avatar/Avatar";
import { RegionSuggestion } from "../../components/AddressSuggestion/RegionSuggestion";
import { getCookie } from "../../AppExtComponents.ts";
import { Text } from "../../language/lang";
import ReactInputMask from "react-input-mask";

export const UserEdit = (props) => {
  const data = props.userData;

  const [country, setCountry] = useState(data?.country);
  const [region, setRegion] = useState(data?.region);
  const [category, setCategory] = useState(data.category);
  const [certification, setCertification] = useState(data.certification);
  const [sex, setSex] = useState(data.sex);
  const [role, setRole] = useState(data.role);
  const [phonePrefix, setPhonePrefix] = useState(data.phone.split(" ")[0]);
  const formRef = useRef(null);
  const imageRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const phoneNumbers = data.phone.split(" ").slice(1).join(" ");

  useEffect(() => {
    if (props.response.show) {
      enqueueSnackbar(props.response.text, {
        variant: props.response.ok ? "success" : "error",
      });
      props.resetResponse();
    }
  }, [props.response]);

  const changeInfo = (e) => {
    e.preventDefault();
    const fd = Object.fromEntries(new FormData(e.target).entries());
    fd.phone = phonePrefix + " " + fd.phone;
    if (fd.phone.match(/\d/g)?.length < 11) {
      enqueueSnackbar(Text.User.NumberMustBeFilled, { variant: "error" });
    } else {
      let diff = _.omit(fd, function (v, k) {
        return data[k] === v;
      });
      if (_.isEqual(diff, {})) {
        enqueueSnackbar(Text.User.NoneOfTheFieldsHaveBeenChanged, {
          variant: "warning",
        });
      } else {
        props.updateUser({
          id: data.id,
          data: diff,
        });
      }
    }
  };

  const changePhoto = (e) => {
    if (e.target.files[0] === undefined) {
      enqueueSnackbar(Text.User.NoImageSelected, { variant: "warning" });
    } else {
      props.updateImage({ id: data.id, image: e.target.files[0] });
      enqueueSnackbar(Text.User.ImageIsLoading, { variant: "info" });
      imageRef.current.src = URL.createObjectURL(e.target.files[0]);
    }
  };

  const changePassword = (e) => {
    e.preventDefault();
    const fd = Object.fromEntries(new FormData(e.target).entries());
    if (fd.old_password === "" || fd.new_password === "") {
      enqueueSnackbar(Text.User.PasswordFieldShouldNotBeEmpty, {
        variant: "warning",
      });
    } else {
      if (props.isAdmin) {
        props.setPasswordFromAdmin({
          id: data.id,
          new_password: fd.new_password,
        });
      } else {
        props.setNewPassword({
          id: data.id,
          old_password: fd.old_password,
          new_password: fd.new_password,
        });
      }
    }
    e.target.reset();
  };

  const getSportsCategoriesItems = () => {
    const currentCategory = data.category;
    const isOld = !SportsCategories.includes(currentCategory);
    let isBlocking = !(
      Boolean(getCookie("admin")) ||
      currentCategory === "" ||
      isOld
    );
    const isAuth = Boolean(getCookie("admin"));
    return SportsCategories.map((el, i) => {
      if (currentCategory === el) isBlocking = false;
      return (
        <MenuItem key={el + i} value={el} disabled={isBlocking}>
          {el}
        </MenuItem>
      );
    });
  };

  const getCertificationItems = () => {
    const currentCertification = data.certification;
    const isOld = !Belts.includes(currentCertification);
    let isBlocking = !(
      Boolean(getCookie("admin")) ||
      currentCertification === "" ||
      isOld
    );
    return Belts.map((el, i) => {
      if (currentCertification === el) isBlocking = false;
      return (
        <MenuItem key={el + i} value={el} disabled={isBlocking}>
          {el}
        </MenuItem>
      );
    });
  };

  const back = () => {
    props.setEditing(false);
  };

  const handleChangePhonePrefix = (e) => {
    const numbers = e.target.value.split("+")[1];
    if (numbers !== undefined) setPhonePrefix("+" + numbers);
  };

  return (
    <div className={s.userEditPage}>
      <div className={s.wrapper}>
        <Button
          variant="text"
          color={"secondary"}
          startIcon={<KeyboardBackspaceIcon />}
          sx={{ justifyContent: "left" }}
          onClick={back}
        >
          {Text.Base.Back}
        </Button>
        <div className={s.section}>
          <Paper className={s.avatar}>
            <Avatar image={data.image} ref={imageRef} isBig />
            <label htmlFor="profile-image">
              <input
                name="image"
                style={{ display: "none" }}
                accept="image/*"
                id="profile-image"
                type="file"
                onChange={changePhoto}
              />
              <LoadingButton
                loading={props.isFetching.image}
                variant="outlined"
                sx={{ width: "100%" }}
                component="span"
              >
                {Text.User.ChangePhoto}
              </LoadingButton>
            </label>
          </Paper>

          <Paper className={s.section__password}>
            <form method="post" onSubmit={changePassword}>
              {!props.isAdmin && (
                <TextField
                  label={Text.Auth.Label.OldPassword}
                  variant="outlined"
                  name="old_password"
                  type="password"
                />
              )}
              <TextField
                label={Text.Auth.Label.NewPassword}
                variant="outlined"
                name="new_password"
                type="password"
              />
              <LoadingButton
                loading={props.isFetching.password}
                variant="contained"
                type="submit"
              >
                {Text.Auth.Label.ChangePassword}
              </LoadingButton>
            </form>
          </Paper>
        </div>

        <Paper className={s.infoSection}>
          <form
            method="post"
            onSubmit={changeInfo}
            className={s.form}
            encType="multipart/form-data"
            ref={formRef}
          >
            <div className={s.grid}>
              <TextField
                defaultValue={data.email}
                label="Email"
                variant="outlined"
                name="email"
                type="email"
                required
              />
              {/* <NumberFormat format='+7 (###) ### - ## - ##' mask='X' defaultValue={data.phone}
                                placeholder='+7 (XXX) XXX - XX - XX' customInput={TextField} name='phone' label={Text.Base.PhoneNumber} required /> */}

              <div className={s.phone}>
                <TextField
                  placeholder="+7"
                  value={phonePrefix}
                  onChange={handleChangePhonePrefix}
                  required
                />

                <ReactInputMask
                  mask={"(999) 999 - 99 - 99"}
                  maskplaceholder={"X"}
                  placeholder="(XXX) XXX - XX - XX"
                  defaultValue={phoneNumbers}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label={Text.Base.PhoneNumber}
                      variant="outlined"
                      name="phone"
                      required
                    />
                  )}
                </ReactInputMask>
              </div>

              {props.isAdmin && (
                <>
                  <TextField
                    defaultValue={data.secondname}
                    label={Text.Base.FullName.Surname}
                    variant="outlined"
                    name="secondname"
                    type="text"
                    required
                  />
                  <TextField
                    defaultValue={data.firstname}
                    label={Text.Base.FullName.Name}
                    variant="outlined"
                    name="firstname"
                    type="text"
                    required
                  />
                  <TextField
                    defaultValue={data.thirdname}
                    label={Text.Base.FullName.Patronymic}
                    variant="outlined"
                    name="thirdname"
                    type="text"
                    required
                  />
                  <DatePickerAuto defaultValue={new Date(data.birth)} />
                </>
              )}

              <CountrySuggestion
                setCountry={setCountry}
                defaultValue={data?.country}
              />
              <RegionSuggestion
                country={country}
                onSelect={setRegion}
                defaultValue={data?.region}
              />
              <CitySuggestion
                country={country}
                region={region}
                isCountryStrict={true}
                defaultValue={data?.city}
              />

              <TextField
                defaultValue={data.height}
                label={Text.Headings.Height}
                variant="outlined"
                name="height"
                type="number"
                inputProps={{ min: 1 }}
              />
              <TextField
                defaultValue={data.weight}
                label={Text.Headings.Weight}
                variant="outlined"
                name="weight"
                type="number"
                inputProps={{ min: 1 }}
              />
              <FormControl fullWidth>
                <InputLabel id="user-edit-category">
                  {Text.Event.SportsCategory}
                </InputLabel>
                <Select
                  labelId="user-edit-category"
                  value={category}
                  label={Text.Event.SportsCategory}
                  onChange={(e) => setCategory(e.target.value)}
                  name="category"
                >
                  {getSportsCategoriesItems()}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="user-edit-certification">
                  {Text.Event.SportsCertification}
                </InputLabel>
                <Select
                  labelId="user-edit-certification"
                  value={certification}
                  label={Text.Event.SportsCertification}
                  onChange={(e) => setCertification(e.target.value)}
                  name="certification"
                >
                  {getCertificationItems()}
                </Select>
              </FormControl>
              <FormControl fullWidth disabled={!props.isAdmin}>
                <InputLabel id="user-edit-sex">Пол</InputLabel>
                <Select
                  labelId="user-edit-sex"
                  value={sex}
                  label={"Пол"}
                  onChange={(e) => setSex(e.target.value)}
                  name="sex"
                >
                  <MenuItem value={"Мужчина"}>Мужской</MenuItem>
                  <MenuItem value={"Женщина"}>Женский</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth disabled={!props.isAdmin}>
                <InputLabel id="user-edit-role">Роль</InputLabel>
                <Select
                  labelId="user-edit-role"
                  value={role}
                  label={"Роль"}
                  onChange={(e) => setRole(e.target.value)}
                  name="role"
                >
                  <MenuItem value={"parent"}>Родитель</MenuItem>
                  <MenuItem value={"manager"}>Менеджер</MenuItem>
                  <MenuItem value={"trainer"}>Тренер</MenuItem>
                  <MenuItem value={"supervisor"}>Руководитель</MenuItem>
                  <MenuItem value={"organizer"}>Организатор</MenuItem>
                  <MenuItem value={"athlete"}>Спортсмен</MenuItem>
                </Select>
              </FormControl>
            </div>

            <LoadingButton
              loading={props.isFetching.info}
              variant="contained"
              type="submit"
            >
              {Text.User.ChangeData}
            </LoadingButton>
          </form>
        </Paper>
      </div>
    </div>
  );
};
