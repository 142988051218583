import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import CustomSelect from "../CustomSelect/CustomSelect";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import FailModal from "./FailModal";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WarningIcon from "@mui/icons-material/Warning";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { nanoid } from "nanoid";
import s from "./FightProcess.module.sass";
import { gr } from "../../AppExtComponents.ts";
import { FetchResult } from "../../constants";
import gong from "./gong.mp3";
import { getUserStatus } from "../../helpers/getUserStatus.jsx";

const ResultStatus = {
  SHOW_RESULT: 0,
  HIDE_RESULT: 1,
  SHOW_END_MESSAGE: 2,
};

const WarningsCounter = ({ onChange, fighter, defaultValue }) => {
  const [count, setCount] = useState(defaultValue);

  const addCount = () => {
    setCount((prevCount) => {
      if (prevCount === 3) {
        onChange({ warningsCount: 0, fighter: fighter });
        return 0;
      } else {
        onChange({ warningsCount: prevCount + 1, fighter: fighter });
        return prevCount + 1;
      }
    });
  };

  return (
    <div className={s.warningsCounter}>
      <span className={s.warningsCounter__title}>Пред-ния</span>
      <Tooltip title="Предупреждения" placement="top">
        <IconButton onClick={addCount}>
          <WarningIcon />
        </IconButton>
      </Tooltip>
      <span>{count}</span>
    </div>
  );
};

const FightProcess = ({
  isOpen,
  onClose,
  roundDuration,
  rounds,
  currentRound,
  fighters,
  fightersAll,
  fightersClubs,
  fightId,
  onRoundSelected,
  addRound,
  addScore,
  saveTime,
  onFail,
  nextRound,
  checkRoundsForEnd,
  resetRound,
  determineRoundWinner,
  determineWinner,
  setFight,
  prepareFightResult,
  fetchingFight,
  fetchingBasket,
  setWarning,
  warnings,
  nextFighters,
  goToNextFight,
  platform,
  highlights,
  isResetTimerRequired,
  resetTimerRequirement,
  basketName,
  nextBasketName,
  isEdit,
  fightNumber,
}) => {
  const defaultTimer = {
    isStarted: false,
    isShowPause: false,
    isCompleted: false,
  };
  const countdownRef = useRef(null);
  const timeHasPassed = useRef(null);
  const [roundTimer, setRoundTimer] = useState({ ...defaultTimer });
  const [isModalOpen, setModalOpen] = useState(false);
  const [showResultStatus, setShowResultStatus] = useState(
    ResultStatus.HIDE_RESULT
  );
  const [duration, setDuration] = useState(Date.now() + roundDuration);

  useEffect(() => {
    if (isResetTimerRequired && countdownRef.current) {
      resetTimerState();
      setDuration(Date.now() + roundDuration);
      resetTimerRequirement();
    }
  }, [isResetTimerRequired]);

  useEffect(() => {
    if (rounds[currentRound].timeFromStart !== 0) {
      setDuration(
        Date.now() + roundDuration - rounds[currentRound].timeFromStart * 1000
      );
      setRoundTimer({
        isStarted: true,
        isShowPause: false,
        isCompleted: true,
      });
    }
  }, [currentRound]);

  useEffect(() => {
    if (fetchingFight.result !== FetchResult.NOT_SET) {
      setShowResultStatus(ResultStatus.SHOW_END_MESSAGE);
    }
  }, [fetchingFight.isLoading === true, fetchingFight.result]);

  const handleChangeRound = (index) => {
    saveTime(timeHasPassed.current);
    resetTimerState();
    onRoundSelected(index);
  };

  const onCloseHandler = () => {
    onClose(); // notify parent about selfclosing
    setTimeout(() => {
      setRoundTimer({ ...defaultTimer });
      setShowResultStatus(ResultStatus.HIDE_RESULT);
    }, 120);
  };

  const onOpenHandler = () => {
    setDuration(Date.now() + roundDuration);
    setFight();
  };

  const start = () => {
    countdownRef.current.getApi().start();
    setRoundTimer({
      ...roundTimer,
      isStarted: true,
      isShowPause: true,
    });
  };

  const pause = () => {
    countdownRef.current.getApi().pause();
    setRoundTimer({
      ...roundTimer,
      isShowPause: false,
    });
  };

  const startHandler = () => {
    if (roundTimer.isShowPause) {
      pause();
    } else {
      start();
    }
  };

  const stop = () => {
    if (roundTimer.isStarted) {
      pause();
    }
    setModalOpen(true); // open failure modal
  };

  function resetTimerState() {
    countdownRef.current.getApi().stop();
    setRoundTimer({ ...defaultTimer });
  }

  const resetHandler = () => {
    resetTimerState();
    resetRound();
  };

  const countdownRenderer = ({ total, minutes, seconds }) => {
    timeHasPassed.current = roundDuration - total;
    return (
      <code className={s.countdown__clock}>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </code>
    );
  };

  const failHandler = ({ fighter, reason }) => {
    onFail({ fighter, reason, time: timeHasPassed.current });
    setRoundTimer({ ...defaultTimer });
    showResults();
    setModalOpen(false);
  };

  const completeTimerHandler = () => {
    const audio = new Audio(gong);
    audio.play();
    setRoundTimer({
      isStarted: true,
      isShowPause: false,
      isCompleted: true,
    });
  };

  const onCloseFailureModal = () => {
    setModalOpen(false); // set state isModalOpen to fasle, when modal closed
  };

  const nextRoundHandler = () => {
    handleChangeRound(currentRound + 2);
  };

  const showResults = () => {
    setShowResultStatus(ResultStatus.SHOW_RESULT);
  };

  const hideResults = () => {
    setShowResultStatus(ResultStatus.HIDE_RESULT);
    setDuration(
      Date.now() + roundDuration - rounds[currentRound].timeFromStart * 1000
    );
  };

  const confirmFightHandler = () => {
    prepareFightResult();
  };

  const getUserTitleByIndex = (index) => {
    let title = fighters[index].split(" ").map((el) => <span>{el}&nbsp;</span>);

    return (
      <div key={title}>
        <div
          className={`${s.userTitle__fio} ${
            index === 0 ? s.userTitle__fio_left : s.userTitle__fio_right
          }`}
        >
          {title}
        </div>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {fightersAll[index]?.status != undefined
            ? getUserStatus({
                status: fightersAll[index]?.status,
                enableText: false,
              })
            : ""}{" "}
          <span className={s.userTitle__club}>
            (
            {fightersClubs[index]?.name
              ? fightersClubs[index]?.name
              : "Нет клуба"}
            )
          </span>
        </Box>
      </div>
    );
  };

  const nextFight = () => {
    setShowResultStatus(ResultStatus.HIDE_RESULT);
    goToNextFight();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 400 }}
        open={fetchingFight.isLoading || fetchingBasket.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalWindow
        isOpen={isOpen}
        willClose={onCloseHandler}
        willOpen={onOpenHandler}
        title={
          <h3>
            Поединок <span className={s.fightNumber}># {fightNumber}</span>
            &nbsp;
            <span style={{ color: highlights[+platform - 1] }}>
              (площадка {platform})
            </span>
            &nbsp;
            <span className={`${s.basketName} ${s.basketName_medium}`}>
              ({basketName})
            </span>
            <span className={s.fightId}>id: {fightId}</span>
          </h3>
        }
        modalClass={s.modal}
      >
        {showResultStatus === ResultStatus.SHOW_END_MESSAGE && (
          <>
            {(fetchingFight.result === FetchResult.GOOD ||
              fetchingFight.result === FetchResult.NOT_SET) && (
              <div className={s.endMessage}>
                <Alert severity="success">
                  <AlertTitle>Готово!</AlertTitle>
                  Вы можете закрыть это окно
                  {Boolean(nextFighters[0]?.fio) &&
                    " или перейти к следующему поединку:"}
                </Alert>
                <br />
                {Boolean(nextFighters[0]?.fio) && (
                  <Button variant="contained" onClick={nextFight}>
                    Следующий поединок
                  </Button>
                )}
              </div>
            )}
            {fetchingFight.result === FetchResult.BAD && (
              <Alert severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                Что-то пошло не так...
              </Alert>
            )}
          </>
        )}

        {showResultStatus === ResultStatus.SHOW_RESULT && (
          <>
            {rounds.map((el, i) => {
              if (el.winner !== null)
                return (
                  <div key={nanoid()} className={s.results}>
                    <span className={s.results__round}>Раунд {i + 1}:</span>
                    <span
                      className={gr(
                        s.results__fighter,
                        s.results__fighter_winner
                      )}
                    >
                      Победитель: {el.winner}
                      <br />
                      <span>
                        Очки:{" "}
                        {el?.winner === fighters[0]
                          ? el?.score?.fighter1
                          : el?.score?.fighter2}
                      </span>
                    </span>
                    <span className={gr(s.results__fighter)}>
                      Проигравший: {el.loser}
                      <br />
                      <span>
                        Очки:{" "}
                        {el?.loser === fighters[0]
                          ? el?.score?.fighter1
                          : el?.score?.fighter2}
                      </span>
                    </span>
                    <span
                      className={gr(
                        s.results__fighter,
                        s.results__fighter_reason
                      )}
                    >
                      Причина: {el.reason}
                    </span>
                  </div>
                );
              else
                return (
                  <div key={nanoid()} className={s.results}>
                    <span className={s.results__round}>Раунд {i + 1}:</span>
                    <span className={gr(s.results__fighter)}>
                      Спортсмен: {fighters[0]}
                      <br />
                      <span>Очки: {el?.score?.fighter1}</span>
                    </span>
                    <span className={gr(s.results__fighter)}>
                      Спортсмен: {fighters[1]}
                      <br />
                      <span>Очки: {el?.score?.fighter2}</span>
                    </span>
                    <span className={s.results__fighter}>
                      Итог: <b>Ничья</b>
                    </span>
                  </div>
                );
            })}

            <div className={s.results}>
              <span className={s.results__round}>ПОБЕДИТЕЛЬ ПОЕДИНКА</span>
              <span className={s.results__fighter}>{determineWinner()}</span>
            </div>

            <div className={s.results__buttons}>
              <Button onClick={hideResults} variant="outlined">
                Назад
              </Button>
              <Button onClick={confirmFightHandler} variant="contained">
                Подтвердить
              </Button>
            </div>
          </>
        )}

        {showResultStatus === ResultStatus.HIDE_RESULT && (
          <div className={s.wrapper}>
            <div>
              <div className={s.headerWrapper}>
                <div className={s.rounds}>
                  <CustomSelect
                    label="Раунд"
                    items={rounds}
                    onChange={handleChangeRound}
                    value={currentRound + 1}
                    variant="outlined"
                  />
                  <Button onClick={addRound} variant="outlined">
                    +1
                  </Button>
                </div>
                <div className={s.nextFighters}>
                  <span className={s.nextFighters__title}>
                    Следующий поединок:
                  </span>
                  {nextFighters[0] === null ? (
                    <span>Поединков больше нет</span>
                  ) : (
                    <div className={s.nextFighters__text}>
                      {nextFighters.map((fighter) => (
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          {fighter.id > 0
                            ? getUserStatus({ status: fighter?.status })
                            : ""}
                          <span>{fighter?.fio}</span>
                        </Box>
                      ))}
                      <span className={`${s.basketName} ${s.basketName_small}`}>
                        {nextBasketName}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!isEdit && (
                <div className={s.countdown}>
                  <Countdown
                    ref={countdownRef}
                    controlled={false}
                    autoStart={false}
                    date={duration}
                    renderer={countdownRenderer}
                    onComplete={completeTimerHandler}
                  />
                  {!roundTimer.isStarted && !roundTimer.isShowPause && (
                    <span>Нажмите на "старт"</span>
                  )}
                  {roundTimer.isStarted && roundTimer.isShowPause && (
                    <span>Идет бой</span>
                  )}
                  {!roundTimer.isShowPause &&
                    roundTimer.isStarted &&
                    !roundTimer.isCompleted && <span>Пауза</span>}
                  {!rounds[currentRound].isAvailable && <span>Завершен</span>}
                  {roundTimer.isCompleted &&
                    rounds[currentRound].isAvailable && (
                      <span>Выставьте очки</span>
                    )}
                </div>
              )}
            </div>

            <div className={s.scores}>
              <div className={s.scores__fighter}>
                <div className={s.scores__title + " " + s.scores__title_left}>
                  {getUserTitleByIndex(0)}
                </div>
                <code className={s.scores__amount}>
                  {rounds[currentRound].score.fighter1}
                </code>
                <div
                  className={`${s.scores__buttonsWrapper} ${s.scores__buttonsWrapper_left}`}
                >
                  <WarningsCounter
                    onChange={setWarning}
                    fighter={fighters[0]}
                    defaultValue={warnings[0].count}
                  />
                  <div className={s.scores__setButtonsWrapper}>
                    <div className={s.scores__setButtons}>
                      {[1, 2, 3, 4, 5, -1].map((amount) => (
                        <Button
                          key={nanoid()}
                          onClick={addScore({
                            fighterIndex: 1,
                            scoreAmount: amount,
                          })}
                          color={amount === -1 ? "error" : "success"}
                          variant="outlined"
                        >
                          {`${amount === -1 ? "" : "+"}${amount}`}
                        </Button>
                      ))}
                    </div>
                    <Button
                      key={nanoid()}
                      onClick={addScore({
                        fighterIndex: 1,
                        scoreAmount: -rounds[currentRound].score.fighter1,
                      })}
                      color={"warning"}
                      variant="outlined"
                    >
                      Обнулить
                    </Button>
                  </div>
                </div>
              </div>

              <Divider orientation="vertical" />

              <div className={s.scores__fighter}>
                <div className={s.scores__title + " " + s.scores__title_right}>
                  {getUserTitleByIndex(1)}
                </div>
                <code className={s.scores__amount}>
                  {rounds[currentRound].score.fighter2}
                </code>
                <div
                  className={`${s.scores__buttonsWrapper} ${s.scores__buttonsWrapper_right}`}
                >
                  <div className={s.scores__setButtonsWrapper}>
                    <div className={s.scores__setButtons}>
                      {[1, 2, 3, 4, 5, -1].map((amount) => (
                        <Button
                          key={nanoid()}
                          onClick={addScore({
                            fighterIndex: 2,
                            scoreAmount: amount,
                          })}
                          color={amount === -1 ? "error" : "success"}
                          variant="outlined"
                        >
                          {`${amount === -1 ? "" : "+"}${amount}`}
                        </Button>
                      ))}
                    </div>
                    <Button
                      key={nanoid()}
                      onClick={addScore({
                        fighterIndex: 2,
                        scoreAmount: -rounds[currentRound].score.fighter2,
                      })}
                      color={"warning"}
                      variant="outlined"
                    >
                      Обнулить
                    </Button>
                  </div>
                  <WarningsCounter
                    onChange={setWarning}
                    fighter={fighters[1]}
                    defaultValue={warnings[1].count}
                  />
                </div>
              </div>
            </div>

            <div className={s.menu}>
              <div></div>

              <div className={s.menu__timeBtns}>
                <IconButton
                  onClick={startHandler}
                  disabled={
                    !rounds[currentRound].isAvailable || roundTimer.isCompleted
                  }
                >
                  {roundTimer.isShowPause ? (
                    <PauseIcon fontSize="large" />
                  ) : (
                    <PlayArrowIcon fontSize="large" />
                  )}
                </IconButton>

                <IconButton onClick={stop}>
                  <StopIcon fontSize="large" />
                </IconButton>

                <IconButton onClick={resetHandler}>
                  <RotateLeftIcon fontSize="large" />
                </IconButton>
              </div>

              <div className={s.menu__confirmBtns}>
                {roundTimer.isCompleted && (
                  <Button onClick={determineRoundWinner} color="success">
                    Подтвердить
                  </Button>
                )}

                {currentRound !== rounds.length - 1 && ( // check for last round
                  <Button
                    onClick={nextRoundHandler}
                    color="info"
                    disabled={roundTimer.isStarted && !roundTimer.isCompleted}
                  >
                    Следующий раунд
                  </Button>
                )}

                {currentRound === rounds.length - 1 && (
                  <>
                    <Button
                      onClick={showResults}
                      disabled={!checkRoundsForEnd()}
                      color="primary"
                    >
                      Завершить поединок
                    </Button>
                  </>
                )}
              </div>

              {isModalOpen && (
                <FailModal
                  fighters={fighters}
                  onComplete={failHandler}
                  isOpen={isModalOpen}
                  onClose={onCloseFailureModal}
                />
              )}
            </div>
          </div>
        )}
      </ModalWindow>
    </>
  );
};

export default FightProcess;
