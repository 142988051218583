// * ===================================== TYPES =====================================
interface State {
  response: {
    show: boolean;
    ok: boolean;
    text: string;
  };
}

interface Action {
  type: string;
  response?: Response;
}

interface Response {
  show: boolean;
  ok: boolean;
  text: string;
}

// * ===================================== ACTIONS =====================================

const SET_RESPONSE = "SET_RESPONSE_alert";
const RESET_RESPONSE = "RESET_RESPONSE_alert";

// * ===================================== REDUCER ==========================================

let initialState: State = {
  response: {
    show: false,
    ok: false,
    text: "",
  },
};

export default function snacksReducer(
  state = initialState,
  action: Action
): State {
  switch (action.type) {
    case SET_RESPONSE:
      return {
        ...state,
        response: action.response,
      };
    case RESET_RESPONSE:
      return {
        ...state,
        response: {
          show: false,
          ok: false,
          text: "",
        },
      };

    default:
      return state;
  }
}

// * ===================================== ACTION CREATORS =====================================

export const setResponse = (response: Response): Action => {
  resetResponse();
  return {
    type: SET_RESPONSE,
    response: { show: response.show, ok: response.ok, text: response.text },
  };
};
export const resetResponse = (): Action => ({ type: RESET_RESPONSE });
export const getReponse = initialState.response;
