import React, { useState, useEffect, useRef } from "react";
import styles from "./RewardingChampions.module.scss";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/ru"; // Подключаем русскую локаль
import { eventAPI } from "../../api/eventAPI";
import { getCookie } from "../../AppExtComponents.ts";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
// Устанавливаем локаль для dayjs
dayjs.locale("ru");
const iconOptions = [
  "fa-solid fa-circle-info",
  "fa-solid fa-star",
  "fa-solid fa-heart",
  "fa-solid fa-bomb",
  "fa-solid fa-bolt",
  "fa-solid fa-gift",
  "fa-solid fa-fire",
  "fa-solid fa-money-bill",
  "fa-solid fa-thumbs-up",
  "fa-solid fa-globe",
  "fa-solid fa-handshake",
  "fa-solid fa-circle",
  "fa-solid fa-flag",
  "fa-solid fa-shield",
  "fa-solid fa-rocket",
  "fa-solid fa-award",
  "fa-solid fa-trophy",
  "fa-solid fa-square-check",
  "fa-solid fa-crown",
  "fa-solid fa-medal",
  "fa-solid fa-circle-check",
  "fa-solid fa-xmark",
  "fa-regular fa-circle-xmark",
  "fa-solid fa-circle-xmark",
  "fa-solid fa-ban",
  "fa-solid fa-circle-exclamation",
];

const colorOptions = [
  "#000000",
  "#008000",
  "#2E8B57",
  "#4B0082",
  "#5F9EA0",
  "#808080",
  "#D3D3D3",
  "#90EE90",
  "#ADD8E6",
  "#1e3a78",
  "#1E90FF",
  "#FFD700",
  "#FF0000",
  "#DC143C",
  "#8B0000",
  "#F08080",
  "#FF1493",
  "#FFB6C1",
  "#D8BFD8",
  "#FF8C00",
  "#9400D3",
];
const defaultData = {
  id: 0,
  offset: 0,
  categoryName: "",
  markedCategory: false,
  crossCategory: false,
  categoryComment: "",
  categoryNote: "",
  categoryIcon: "fa-solid fa-pen",
  categoryColor: "#1e3a78",
  gold: {
    fio: "",
    details: "",
    checked: true,
    crossed: true,
  },
  silver: {
    fio: "",
    details: "",
    checked: true,
    crossed: true,
  },
  bronze: {
    fio: "",
    details: "",
    checked: true,
    crossed: true,
  },
};

export default function RewardingChampions() {
  // Управление временем начала
  const [selectedTime, setSelectedTime] = useState();
  const [timeInterval, setTimeInterval] = useState(5);
  const [categories, setCategories] = useState([defaultData]);

  // Подсчёт оставшихся медалей Отмечено
  const [goldCount, setGoldCount] = useState(0);
  const [silverCount, setSilverCount] = useState(0);
  const [bronzeCount, setBronzeCount] = useState(0);
  //Всего
  const [allGoldCount, setAllGoldCount] = useState(0);
  const [allSilverCount, setAllSilverCount] = useState(0);
  const [allBronzeCount, setAllBronzeCount] = useState(0);

  const [notGoldCount, setNotGoldCount] = useState(0);
  const [notSilverCount, setNotSilverCount] = useState(0);
  const [NotBronzeCount, setNotBronzeCount] = useState(0);
  // Для хранения рассчитанного времени на каждую категорию
  const [displayTimes, setDisplayTimes] = useState([]);

  // Состояние для Drag & Drop (id перетаскиваемой категории)
  const [draggingId, setDraggingId] = useState(null);
  const isAdmin = Boolean(getCookie("admin"));
  const { eventID } = useParams();

  //Заметки
  const [modalVisible, setModalVisible] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [modalText, setModalText] = useState("");
  const [isNoteMode, setIsNoteMode] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("fa-solid fa-pen");
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [showIcons, setShowIcons] = useState(false);
  const [showColors, setShowColors] = useState(false);
  // Пример для tooltip (демонстрация), без TS
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: "",
    x: 0,
    y: 0,
  });
  const openModal = (entry, isNote = false) => {
    if (isAdmin) {
      setCurrentEntry(entry);
      setModalText(
        isNote ? entry.categoryNote || "" : entry.categoryComment || ""
      );
      setSelectedIcon(entry.categoryIcon);
      if (entry.categoryColor) {
        setSelectedColor(entry.categoryColor);
      }

      setIsNoteMode(isNote);
      setModalVisible(true);
    }
  };

  const saveChanges = () => {
    if (currentEntry) {
      const updatedData = categories.map((item) =>
        item === currentEntry
          ? {
              ...item,
              categoryIcon: selectedIcon,
              categoryColor: selectedColor,
              categoryNote: isNoteMode ? modalText : item.categoryNote,
              categoryComment: !isNoteMode ? modalText : item.categoryComment,
            }
          : item
      );
      sendData(updatedData);
      // setCategories(updatedData);
      setModalVisible(false);
    }
  };

  const deleteEntry = () => {
    if (currentEntry) {
      const updatedData = categories.map((item) =>
        item === currentEntry
          ? {
              ...item,
              categoryIcon: "fa-solid fa-pen",
              categoryColor: "#1e3a78",
              categoryNote: isNoteMode ? "" : item.categoryNote,
              categoryComment: !isNoteMode ? "" : item.categoryComment,
            }
          : item
      );
      sendData(updatedData);
      // setCategories(updatedData);
      setModalVisible(false);
    }
  };
  useEffect(() => {
    eventAPI.GetAwarding({ eventId: eventID }).then((data) => {
      setCategories(
        data.data
          .map((elem) => checkAndFillKeys(elem))
          .sort((a, b) => a.offset - b.offset)
      );
      if (Number(data.settings.awarding_start) > 0) {
        setSelectedTime(dayjs(data.settings.awarding_start * 1000));
        setTimeInterval(Number(data.settings.awarding_interval));
      }
    });
  }, []);
  const sendData = (newData) => {
    const data = newData.map((elem, i) => {
      elem.offset = i;
      return elem;
    });

    eventAPI
      .UpdateAwarding({ eventId: eventID, awarding: data })
      .then((data) => {
        setCategories(data.data.sort((a, b) => a.offset - b.offset));
      });
  };
  const sendDataDebounced = debounce((newCategories) => {
    sendData(newCategories);
  }, 500);
  const checkAndFillKeys = (data) => {
    const filledData = { ...data };

    // Проверяем и добавляем отсутствующие ключи
    Object.keys(defaultData).forEach((key) => {
      if (key == "categoryIcon" && !filledData[key].length) {
        filledData[key] = defaultData.categoryIcon;
      }
      if (!(key in filledData)) {
        filledData[key] = defaultData[key];
      } else if (
        typeof defaultData[key] === "object" &&
        !Array.isArray(defaultData[key])
      ) {
        filledData[key] = { ...defaultData[key], ...filledData[key] };
      }
    });

    return filledData;
  };
  // Форматирование времени (ЧЧ:ММ)
  const fmtTime = (date) => {
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");
    return `${hh}:${mm}`;
  };

  // Пересчитываем время для каждой строки
  const recalcTimes = (cats, selectedTime) => {
    const interval = Number(timeInterval) || 5;
    let baseTime = new Date();

    // Если пользователь задал время начала
    if (selectedTime && dayjs(selectedTime).isValid()) {
      baseTime = dayjs(selectedTime, "HH:mm").toDate(); // Преобразуем строку в Date
    }

    const newTimes = cats.map((cat, idx) => {
      const offsetMinutes = idx * interval;
      const newDate = new Date(baseTime.getTime() + offsetMinutes * 60000);

      return fmtTime(newDate);
    });

    setDisplayTimes(newTimes);
  };

  // Подсчитать оставшиеся медали
  const countMedals = (cats) => {
    let totalGold = 0;
    let checkedGold = 0;
    let totalSilver = 0;
    let checkedSilver = 0;
    let totalBronze = 0;
    let checkedBronze = 0;

    cats.forEach((cat) => {
      // Проверяем, есть ли золотая медаль в категории
      if (cat.gold.fio) {
        totalGold += 1;
        if (cat?.gold?.checked) checkedGold++;
      }

      // Проверяем, есть ли серебряная медаль в категории
      if (cat.silver.fio) {
        totalSilver += 1;
        if (cat?.silver?.checked) checkedSilver++;
      }

      // Проверяем, есть ли бронзовая медаль в категории
      if (cat.bronze.fio) {
        totalBronze += 1;
        if (cat?.bronze?.checked) checkedBronze++;
      }
    });
    setAllGoldCount(totalGold);
    setAllSilverCount(totalSilver);
    setAllBronzeCount(totalBronze);

    setGoldCount(checkedGold);
    setSilverCount(checkedSilver);
    setBronzeCount(checkedBronze);

    setNotGoldCount(totalGold - checkedGold);
    setNotSilverCount(totalSilver - checkedSilver);
    setNotBronzeCount(totalBronze - checkedBronze);
  };

  // При изменении categories, selectedTime или timeInterval — пересчитываем
  useEffect(() => {
    if (selectedTime?.$H) {
      eventAPI.UpdateAwardingSettings({
        eventId: eventID,
        awardingStart: selectedTime.$d,
        awardingInterval: timeInterval,
      });
      recalcTimes(categories, selectedTime);
    }
  }, [selectedTime, timeInterval]);

  // Обработчики для чекбоксов категории
  const onToggleCategory = (catId, checked) => {
    const prevCheck = categories.map((c) => {
      if (c.id === catId) {
        return {
          ...c,
          markedCategory: checked,
          crossCategory: false,
          gold: { ...c.gold, checked, crossed: false },
          silver: { ...c.silver, checked, crossed: false },
          bronze: { ...c.bronze, checked, crossed: false },
        };
      }
      return c;
    });
    sendData(filterAllMedal(prevCheck, catId));
  };

  // Крестик для всей категории
  const onToggleCategoryCross = (catId, active) => {
    const prevCross = categories.map((c) => {
      if (c.id === catId) {
        return {
          ...c,
          crossCategory: active,
          markedCategory: false,
          gold: { ...c.gold, crossed: active, checked: false },
          silver: { ...c.silver, crossed: active, checked: false },
          bronze: { ...c.bronze, crossed: active, checked: false },
        };
      }
      return c;
    });

    sendData(filterAllMedal(prevCross, catId));
  };

  // Обработчики для чекбоксов спортсменов (gold/silver/bronze)
  const onToggleMedal = (catId, place, checked) => {
    const prevCheck = categories.map((c) => {
      if (c.id === catId) {
        const countParticipant = () => {
          let count = 0;
          if (c.gold.fio) {
            count++;
          }
          if (c.silver.fio) {
            count++;
          }
          if (c.bronze.fio) {
            count++;
          }
          return count;
        };
        const checkedCount =
          (c.gold.fio && c.gold.checked ? 1 : 0) +
          (c.silver.fio && c.silver.checked ? 1 : 0) +
          (c.bronze.fio && c.bronze.checked ? 1 : 0) +
          (checked ? 1 : -1);

        return {
          ...c,
          markedCategory: checkedCount == countParticipant(),
          crossCategory: false,
          [place]: {
            ...c[place],
            checked,
            crossed: checked ? false : c[place].crossed,
          },
        };
      }
      return c;
    });

    sendData(filterAllMedal(prevCheck, catId));
  };

  // Обработчик крестика (place)
  const onToggleCross = (catId, place, crossed) => {
    const prevCross = categories.map((c) => {
      if (c.id === catId) {
        const countParticipant = () => {
          let count = 0;
          if (c.gold.fio) {
            count++;
          }
          if (c.silver.fio) {
            count++;
          }
          if (c.bronze.fio) {
            count++;
          }
          return count;
        };
        const checkedCount =
          (c.gold.fio && c.gold.crossed ? 1 : 0) +
          (c.silver.fio && c.silver.crossed ? 1 : 0) +
          (c.bronze.fio && c.bronze.crossed ? 1 : 0) +
          (crossed ? 1 : -1);
        return {
          ...c,
          crossCategory: checkedCount == countParticipant(),
          markedCategory: false,
          [place]: {
            ...c[place],
            crossed,
            checked: crossed ? false : c[place].checked,
          },
        };
      }
      return c;
    });

    sendData(filterAllMedal(prevCross, catId));
  };
  const filterAllMedal = (prevCategories, catId) => {
    const allCross = prevCategories.some((cat) => {
      // Проверка, полностью ли награждена категория и не находится ли она в исключении
      if ((cat.markedCategory || cat.crossCategory) && cat.id == catId) {
        return true;
      } else {
        return false;
      }
    });

    if (allCross) {
      return moveToBottomIfFullyAwarded(prevCategories, catId);
    } else {
      return prevCategories; // Возвращаем исходный массив, если нет полностью награждённых категорий
    }
  };

  // Проверка, вся ли категория награждена (все 3 места либо галочка, либо крестик)
  const isCategoryFullyAwarded = (cat) => {
    const { gold, silver, bronze } = cat;
    const g = gold.checked || gold.crossed;
    const s = silver.checked || silver.crossed;
    const b = bronze.checked || bronze.crossed;

    return g && s && b;
  };

  // Переместить категорию вниз, если она полностью награждена
  const moveToBottomIfFullyAwarded = (prev, catId) => {
    const found = prev.find((c) => c.id === catId);

    if (!found) return prev;
    if (found) {
      const filtered = prev.filter((c) => c.id !== catId);

      return [...filtered, found];
    }
    return prev;
  };

  // Следим за изменениями categories и автоматически двигаем в конец, если всё награждено
  useEffect(() => {
    countMedals(categories);
  }, [categories]);

  // Кнопки up/down/last
  const moveRow = (catId, action) => {
    const index = categories.findIndex((c) => c.id === catId);
    if (index === -1) return categories;
    const newArr = [...categories];
    const [removed] = newArr.splice(index, 1);

    switch (action) {
      case "up":
        newArr.splice(Math.max(0, index - 1), 0, removed);
        break;
      case "down":
        newArr.splice(Math.min(newArr.length, index + 1), 0, removed);
        break;
      case "last":
        newArr.push(removed);
        break;
      default:
        break;
    }

    sendData(newArr);
    // return newArr;
  };

  // Drag & Drop (упрощённая логика)
  const onDragStart = (catId, e) => {
    setDraggingId(catId);
    e.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = (catId, e) => {
    e.preventDefault();
    if (draggingId === null) return;
    if (draggingId === catId) return;

    const bounding = e.currentTarget.getBoundingClientRect();
    const offset = e.clientY - bounding.top;
    const half = bounding.height / 2;
    setCategories((prev) => {
      const draggingIndex = prev.findIndex((c) => c.id === draggingId);
      const targetIndex = prev.findIndex((c) => c.id === catId);
      if (draggingIndex === -1 || targetIndex === -1) return prev;

      if (draggingIndex === targetIndex) return prev;

      const newList = [...prev];
      const [dragItem] = newList.splice(draggingIndex, 1);

      let insertIndex = targetIndex;
      if (offset > half) {
        insertIndex = targetIndex + 1;
      }
      newList.splice(insertIndex, 0, dragItem);

      return newList;
    });
  };

  const onDragEnd = () => {
    setDraggingId(null);
    sendDataDebounced(categories);
  };

  // Обработчик изменения времени
  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  // Обработчик изменения интервала
  const handleIntervalChange = (e) => {
    const value = e.target.value;
    // Убедитесь, что значение является числом и находится в допустимом диапазоне
    const numberValue = Number(value);
    if (numberValue >= 1 && numberValue <= 60) {
      setTimeInterval(numberValue);
    }
  };

  return (
    <div className={styles.wrapperContainet}>
      <div className={styles.container}>
        {/* Блок настроек времени */}

        <div className={styles.timeSettings}>
          <div className={styles.timeSetting}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <TimePicker
                disabled={!isAdmin}
                label="Начало"
                value={selectedTime}
                onChange={handleTimeChange}
                className={
                  selectedTime == null ? styles.timePicker : styles.timePicker2
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px", // Высота поля ввода
                    padding: "0 8px", // Отступы внутри поля
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "40px", // Выравнивание метки по вертикали
                    transform: "translate(14px, 0)", // Начальная позиция метки
                    "&.Mui-focused": {
                      transform: "translate(14px, -20px)", // При фокусе метка перемещается вверх
                      fontSize: "0.75rem", // Размер текста метки при фокусе
                    },
                    // Когда в поле уже есть значение, метка не будет накладываться
                    "&.MuiInputLabel-shrink": {
                      transform: "translate(14px, -20px)", // Смещение метки при наличии текста
                      fontSize: "0.75rem", // Размер текста метки при наличии текста
                    },
                  },
                }}
                ampm={false} // Отключаем AM/PM для 24-часового формата
                inputFormat="HH:mm" // Формат ввода времени
                helperText="часы:минуты"
                placeholder="часы:минуты"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    helperText="часы:минуты"
                    placeholder="часы:минуты"
                    className={styles.inputField}
                  />
                )}
              />
            </LocalizationProvider>{" "}
            <TextField
              disabled={!isAdmin}
              label="Интервал"
              type="number"
              id="globalTimeInterval"
              value={timeInterval}
              onChange={handleIntervalChange}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 60,
                },
              }}
              variant="outlined"
              size="small"
              className={`${styles.inputField} ${styles.intervalInput}`}
            />{" "}
          </div>
          Награды
          <div className={styles.medalsCounter}>
            <h3>Всего:</h3>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>1</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{allGoldCount}</span>
            </div>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>2</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{allSilverCount}</span>
            </div>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>3</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{allBronzeCount}</span>
            </div>
          </div>
          <div className={styles.medalsCounter}>
            <h3>Отмечено:</h3>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>1</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{goldCount}</span>
            </div>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>2</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{silverCount}</span>
            </div>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>3</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{bronzeCount}</span>
            </div>
          </div>
          <div className={styles.medalsCounter}>
            <h3>Не отмечено:</h3>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>1</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{notGoldCount}</span>
            </div>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>2</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{notSilverCount}</span>
            </div>
            <div className={styles.medalCount}>
              <div className={styles.placeNumber}>3</div>
              <span>:</span>
              <span className={styles.medalCountSpan}>{NotBronzeCount}</span>
            </div>
          </div>
        </div>

        {/* Таблица */}
        <table>
          <colgroup>
            <col className={styles.timeCol} />
            <col span="4" />
          </colgroup>
          <thead>
            <tr>
              <th>Время</th>
              <th>Категория</th>
              <th>1 место</th>
              <th>2 место</th>
              <th>3 место</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((cat, index) => {
              // Собираем классы для <tr>
              const rowClass = [
                cat.markedCategory ? styles.markedCategory : "",
                draggingId === cat.id ? styles.dragging : "",
              ]
                .filter(Boolean)
                .join(" ");
              return (
                <tr
                  key={cat.id}
                  className={rowClass}
                  draggable={isAdmin}
                  onDragStart={(e) => onDragStart(cat.id, e)}
                  onDragOver={(e) => onDragOver(cat.id, e)}
                  onDragEnd={onDragEnd}
                >
                  {/* Время */}
                  <td className={`${styles.timeCell} ${styles.timeDisplay}`}>
                    <span>
                      {cat.markedCategory || cat.crossCategory
                        ? cat.updated_at
                        : displayTimes[index]}
                    </span>
                  </td>

                  {/* Категория */}
                  <td>
                    <div className={styles.wrapperButtonscheckbox}>
                      {isAdmin && (
                        <div
                          className={`${styles.checkboxContainer} ${styles.alignLeft}`}
                        >
                          <input
                            type="checkbox"
                            checked={cat.markedCategory}
                            onChange={(e) =>
                              onToggleCategory(cat.id, e.target.checked)
                            }
                          />
                          <span className={styles.checkmark}></span>{" "}
                          {/* Крестик категории */}
                          <input
                            type="checkbox"
                            className={styles.inputCrossMark}
                            onChange={(e) =>
                              onToggleCategoryCross(cat.id, e.target.checked)
                            }
                            checked={cat.crossCategory}
                          />
                          <span
                            className={`${styles.crossMark} ${
                              cat.crossCategory ? styles.crossMarkActive : ""
                            }`}
                          ></span>
                        </div>
                      )}

                      {isAdmin && (
                        <div className={styles.rowControls}>
                          <button
                            className={styles.moveBtn}
                            onClick={() => moveRow(cat.id, "up")}
                            title="Выше"
                          >
                            <i className="fas fa-chevron-up"></i>
                          </button>
                          <button
                            className={styles.moveBtn}
                            onClick={() => moveRow(cat.id, "down")}
                            title="Ниже"
                          >
                            <i className="fas fa-chevron-down"></i>
                          </button>
                          <button
                            className={styles.moveBtn}
                            onClick={() => moveRow(cat.id, "last")}
                            title="В конец"
                          >
                            <i className="fas fa-angle-double-down"></i>
                          </button>
                        </div>
                      )}
                      <div className={styles.editable}>
                        {isAdmin && (
                          <i
                            className={cat?.categoryIcon}
                            style={{ color: cat?.categoryColor }}
                            onClick={() => openModal(cat, true)}
                            {...(cat.categoryNote
                              ? { "data-tooltip": cat.categoryNote }
                              : {})}
                          ></i>
                        )}
                        <i
                          className="fa-regular fa-message"
                          onClick={() => openModal(cat, false)}
                          {...(cat.categoryComment
                            ? { "data-tooltip": cat.categoryComment }
                            : {})}
                        ></i>
                      </div>
                    </div>

                    <div className={styles.categoryWrapper}>
                      {/* Название категории */}
                      <span
                        className={[
                          styles.categoryLabel,
                          cat.markedCategory ? styles.categoryChecked : "",
                          cat.crossCategory ? styles.grayMedal : "",
                        ]
                          .filter(Boolean)
                          .join(" ")}
                      >
                        {cat?.categoryName}
                      </span>
                    </div>
                  </td>

                  {/* Gold */}
                  <td className={styles.gold}>
                    {cat.gold.fio && (
                      <label style={{ width: "100%", textAlign: "left" }}>
                        <div className={styles.placeInfo}>
                          <div className={styles.wrapperInfo}>
                            {isAdmin && (
                              <div className={styles.checkboxContainer}>
                                <input
                                  type="checkbox"
                                  checked={cat?.gold?.checked}
                                  className={styles.inputChecmark}
                                  onChange={(e) =>
                                    onToggleMedal(
                                      cat.id,
                                      "gold",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className={styles.checkmark}></span>
                                <input
                                  type="checkbox"
                                  className={styles.inputCrossMark}
                                  checked={cat?.gold?.crossed}
                                  onChange={(e) =>
                                    onToggleCross(
                                      cat.id,
                                      "gold",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span
                                  className={`${styles.crossMark} ${
                                    cat.gold.crossed
                                      ? styles.crossMarkActive
                                      : ""
                                  }`}
                                ></span>
                              </div>
                            )}
                            <span className={styles.fio}>
                              {cat?.gold?.checked && (
                                <i
                                  className={`fa-solid fa-medal ${styles.medalChecked}`}
                                />
                              )}
                              {cat.gold.crossed && (
                                <i
                                  className={`fa-solid fa-medal ${styles.medalCrossed}`}
                                />
                              )}
                              {cat?.gold?.fio}
                            </span>
                          </div>

                          <span className={styles.details}>
                            {cat?.gold?.details}
                          </span>
                        </div>
                      </label>
                    )}
                  </td>

                  {/* Silver */}
                  <td className={styles.silver}>
                    {cat.silver.fio && (
                      <label style={{ width: "100%", textAlign: "left" }}>
                        <div className={styles.placeInfo}>
                          <div className={styles.wrapperInfo}>
                            {isAdmin && (
                              <div className={styles.checkboxContainer}>
                                <input
                                  type="checkbox"
                                  checked={cat?.silver?.checked}
                                  className={styles.inputChecmark}
                                  onChange={(e) =>
                                    onToggleMedal(
                                      cat.id,
                                      "silver",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className={styles.checkmark}></span>
                                <input
                                  type="checkbox"
                                  className={styles.inputCrossMark}
                                  checked={cat?.silver?.crossed}
                                  onChange={(e) =>
                                    onToggleCross(
                                      cat.id,
                                      "silver",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span
                                  className={`${styles.crossMark} ${
                                    cat?.silver?.crossed
                                      ? styles.crossMarkActive
                                      : ""
                                  }`}
                                ></span>
                              </div>
                            )}
                            <span className={styles.fio}>
                              {cat?.silver?.checked && (
                                <i
                                  className={`fa-solid fa-medal ${styles.medalChecked}`}
                                />
                              )}
                              {cat?.silver?.crossed && (
                                <i
                                  className={`fa-solid fa-medal ${styles.medalCrossed}`}
                                />
                              )}
                              {cat?.silver?.fio}
                            </span>
                          </div>

                          <span className={styles.details}>
                            {cat?.silver?.details}
                          </span>
                        </div>
                      </label>
                    )}
                  </td>

                  {/* Bronze */}
                  <td className={styles.bronze}>
                    {cat.bronze.fio && (
                      <label style={{ width: "100%", textAlign: "left" }}>
                        <div className={styles.placeInfo}>
                          <div className={styles.wrapperInfo}>
                            {isAdmin && (
                              <div className={styles.checkboxContainer}>
                                <input
                                  type="checkbox"
                                  checked={cat?.bronze?.checked}
                                  className={styles.inputChecmark}
                                  onChange={(e) =>
                                    onToggleMedal(
                                      cat.id,
                                      "bronze",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className={styles.checkmark}></span>
                                <input
                                  type="checkbox"
                                  className={styles.inputCrossMark}
                                  checked={cat?.bronze?.crossed}
                                  onChange={(e) =>
                                    onToggleCross(
                                      cat.id,
                                      "bronze",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span
                                  className={`${styles.crossMark} ${
                                    cat?.bronze?.crossed
                                      ? styles.crossMarkActive
                                      : ""
                                  }`}
                                ></span>
                              </div>
                            )}
                            <span className={styles.fio}>
                              {cat?.bronze?.checked && (
                                <i
                                  className={`fa-solid fa-medal ${styles.medalChecked}`}
                                />
                              )}
                              {cat?.bronze?.crossed && (
                                <i
                                  className={`fa-solid fa-medal ${styles.medalCrossed}`}
                                />
                              )}
                              {cat?.bronze?.fio}
                            </span>
                          </div>

                          <span className={styles.details}>
                            {cat?.bronze?.details}
                          </span>
                        </div>
                      </label>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {modalVisible && (
        <div
          className={styles.modalOverlay}
          onClick={() => setModalVisible(false)}
        />
      )}
      {modalVisible && (
        <div className={styles.modal}>
          <textarea
            value={modalText}
            onChange={(e) => setModalText(e.target.value)}
            placeholder={
              isNoteMode ? "Добавьте примечание..." : "Добавьте комментарий..."
            }
            className={styles.textarea}
          />
          {isNoteMode && (
            <div className={styles.controlsRow}>
              <div
                className={`${styles.iconPreview} ${showIcons ? "active" : ""}`}
                onClick={() => {
                  setShowIcons(!showIcons);
                  setShowColors(false); // Закрыть цвета
                }}
              >
                <i
                  className={selectedIcon}
                  style={{ color: selectedColor }}
                ></i>{" "}
                Иконка
              </div>
              <div
                className={`${styles.colorPreview} ${
                  showColors ? "active" : ""
                }`}
                onClick={() => {
                  setShowColors(!showColors);
                  setShowIcons(false); // Закрыть иконки
                }}
              >
                <div
                  className={styles.colorBox}
                  style={{ backgroundColor: selectedColor }}
                ></div>
                Цвет
              </div>
            </div>
          )}
          {showIcons && (
            <div className={styles.iconGrid}>
              {iconOptions.map((icon, index) => (
                <i
                  key={index}
                  className={icon}
                  onClick={() => {
                    setSelectedIcon(icon);
                    setShowIcons(false);
                  }}
                ></i>
              ))}
            </div>
          )}
          {showColors && (
            <div className={styles.colorGrid}>
              {colorOptions.map((color, index) => (
                <div
                  key={index}
                  className={`${styles.colorOption} ${
                    selectedColor === color ? styles.selectedColor : ""
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => {
                    setSelectedColor(color);
                    setShowColors(false);
                  }}
                ></div>
              ))}
            </div>
          )}
          <div className={styles.buttonGroup}>
            <button className={styles.primary} onClick={saveChanges}>
              Сохранить
            </button>
            <button
              className={styles.secondary}
              onClick={() => setModalVisible(false)}
            >
              Отмена
            </button>
            <button className={styles.secondary} onClick={deleteEntry}>
              Удалить
            </button>
          </div>
        </div>
      )}
      {/* Tooltip (для примера, если нужно) */}
      {tooltip.visible && (
        <div
          className={`${styles.tooltip} ${styles.tooltipVisible}`}
          style={{ left: tooltip.x, top: tooltip.y }}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
}
