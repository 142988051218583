import axios from "axios";
import { deleteCookie } from "../AppExtComponents.ts";
import { ROOT_URL } from "../constants";
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper.js";

const instance = langWrapper(
  wrapToJWT(
    axios.create({
      baseURL: ROOT_URL + "/action.php",
    })
  )
);

const eventAPI = {
  addEvent(dataObj) {
    const fd = new FormData();
    fd.append("action", "addEvent");
    fd.append("data", JSON.stringify(dataObj));
    fd.append("picture", dataObj.picture);
    return instance.post("/", fd).then((response) => response.data);
  },

  deleteEvent({ id, ...args }) {
    return instance
      .post("/", {
        action: "deleteEvent",
        data: { id, ...args },
      })
      .then((response) => response.data);
  },

  getEvent({ id }) {
    return instance
      .post("/", {
        action: "getEvent",
        data: { id },
      })
      .then((response) => response.data);
  },

  registrationForEvent(data) {
    data.append("action", "registrationForEvent");
    return instance.post("/", data).then((response) => response.data);
  },

  updateUserDataOnEvent(data) {
    return instance
      .post("/", {
        action: "updateUserDataOnEvent",
        data: data,
      })
      .then((response) => response.data);
  },

  getEventUsers({ id, page, count, user_id }) {
    return instance
      .post("/", {
        action: "getEventUsers",
        data: { id, page, count, user_id },
      })
      .then((response) => response.data);
  },

  updateEventUser(dataObj) {
    return instance
      .post("/", {
        action: "updateEventUser",
        data: dataObj,
      })
      .then((response) => response.data);
  },

  updateEventSchedule({ id, schedule, ...args }) {
    return instance
      .post("/", {
        action: "updateEventSchedule",
        data: { id, schedule, ...args },
      })
      .then((response) => response.data);
  },

  deleteFromEvent({ id, user_id, ...args }) {
    return instance
      .post("/", {
        action: "deleteFromEvent",
        data: { id, user_id, ...args },
      })
      .then((response) => response.data);
  },

  updateEvent(data) {
    return instance
      .post("/", {
        action: "updateEvent",
        data,
      })
      .then((response) => response.data);
  },

  updateEventCategories({ id, disciplines, ...args }) {
    return instance
      .post("/", {
        action: "updateEventCategories",
        data: { id, disciplines, ...args },
      })
      .then((response) => response.data);
  },

  updateEventImage({ id, picture }) {
    const fd = new FormData();
    fd.append("action", "updateEventImage");
    fd.append("id", id);
    fd.append("picture", picture);
    return instance.post("/", fd).then((response) => response.data);
  },

  getCountUsers({ id }) {
    return instance
      .post("/", {
        action: "getCountUsers",
        data: { id },
      })
      .then((response) => response.data);
  },

  getCountBaskets({ id }) {
    return instance
      .post("/", {
        action: "getCountBaskets",
        data: { id },
      })
      .then((response) => response.data);
  },

  getUsersList({ id }) {
    return instance
      .post("/", {
        action: "getUsersList",
        data: { id },
      })
      .then((response) => response.data);
  },
  GetEventProgress({ id }) {
    return instance
      .post("/", {
        action: "GetEventProgress",
        data: { id },
      })
      .then((response) => response.data);
  },

  getResultsByClubs({ id, ...args }) {
    return instance
      .post("/", {
        action: "getResultsByClubs",
        data: { id, ...args },
      })
      .then((response) => response.data);
  },

  getWinners({ id }) {
    return instance
      .post("/", {
        action: "getWinners",
        data: { id },
      })
      .then((response) => response.data);
  },
  updateTranslationUrls({ id, urls }) {
    return instance
      .post("/", {
        action: "updateTranslationUrls",
        data: { id, urls },
      })
      .then((response) => response.data);
  },
  GetAwarding({ eventId }) {
    return instance
      .post("/", {
        action: "GetAwarding",
        data: { event_id: eventId },
      })
      .then((response) => response.data);
  },
  UpdateAwarding({ eventId, awarding }) {
    return instance
      .post("/", {
        action: "UpdateAwarding",
        data: { event_id: eventId, awarding: awarding },
      })
      .then((response) => response.data);
  },
  UpdateAwardingSettings({ eventId, awardingStart, awardingInterval }) {
    return instance
      .post("/", {
        action: "UpdateAwardingSettings",
        data: {
          event_id: eventId,
          awarding_start: awardingStart,
          awarding_interval: awardingInterval,
        },
      })
      .then((response) => response.data);
  },
};

const device = `OS: ${window.navigator.platform}; USER_AGENT: ${window.navigator.userAgent}`;

const proxyEventAPI = Object.keys(eventAPI).reduce((prev, key) => {
  return {
    ...prev,
    [key]: (data) => {
      if (data instanceof FormData) return eventAPI[key](data);
      return eventAPI[key]({
        ...data,
        _device: device,
      });
    },
  };
}, {});

export { proxyEventAPI as eventAPI };
