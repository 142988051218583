import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import getEventPrice from "../../helpers/getEventPrice";
import { resetPaymentState } from "../../redux/reducers/payment/payment";
import { setFetching } from "../../redux/reducers/event/event.ts";
import { Text } from "../../language/lang";
import { ROOT_URL } from "../../constants";
import { Alert, AlertTitle, Box, Stack } from "@mui/material";
import { payTbank } from "../../api/walletAPI.js";

const RegistrationPayment = () => {
  const { eventId, users, price, discount, account } = useSelector(
    (state) => state.payment
  );
  const dispatch = useDispatch();
  const targets =
    "%D0%92%D0%B7%D0%BD%D0%BE%D1%81%20%D0%BD%D0%B0%20%D1%81%D0%BE%D1%80%D0%B5%D0%B2%D0%BD%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5";
  const targetURL = `${ROOT_URL}/success_yoomoney.php`;
  const descriptionRef = useRef(null);

  useEffect(() => {
    parseDescription(Text.Payment.PaymentDescription);
    return () => {
      dispatch(resetPaymentState());
      dispatch(
        setFetching({
          register: {
            status: false,
            proceed: false,
          },
        })
      );
    };
  }, [dispatch]);

  const getSum = () => {
    return users.reduce((prev, current) => {
      return (
        prev +
        getEventPrice({ price, discount, disciplines: current.disciplines })
      );
    }, 0);
  };

  const getSuccessURL = () => {
    return encodeURI(
      targetURL + `?id=${eventId};${users.map((el) => el.id).join(",")}`
    );
  };

  const parseDescription = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    descriptionRef.current.innerHTML = doc.querySelector("body").innerHTML;
  };

  const pay = () => {
    const send = { id: eventId, users: [Number(users[0].id)], sum: getSum() };
    payTbank({ ...send });
    console.log(eventId);
  };
  return (
    <Stack spacing={2}>
      <span ref={descriptionRef}></span>
      <Alert severity="warning">
        <AlertTitle>{Text.Payment.Warning}</AlertTitle>
        {Text.Payment.Alert}
      </Alert>
      <b>
        {Text.Payment.ToBePaid}: {getSum()}руб.
      </b>
      {/* <Box sx={{ textAlign: "center", color: "#2f80ed", fontWeight: 700 }}>
        Оплата через сайт не проводится! Уточняйте у организатора о способах
        оплаты взноса на турнир
      </Box> */}
      {/* <iframe
        title=" "
        src={`https://yoomoney.ru/quickpay/button-widget?targets=${targets}&default-sum=${getSum()}&button-text=12&yoomoney-payment-type=on&button-size=m&button-color=white&successURL=${getSuccessURL()}&quickpay=small&account=${account}&`}
        width="320"
        height="46"
        frameborder="0"
        allowtransparency="true"
        scrolling="no"
      ></iframe> */}
      <div
        className="tbank"
        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
        onClick={pay}
      >
        <svg
          width="100"
          height="50"
          viewBox="0 0 1036 407"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2883_1199)">
            <path
              d="M990.038 0H45.5907C20.4116 0 0 20.4116 0 45.5907V360.931C0 386.11 20.4116 406.522 45.5907 406.522H990.038C1015.22 406.522 1035.63 386.11 1035.63 360.931V45.5907C1035.63 20.4116 1015.22 0 990.038 0Z"
              fill="#333333"
            />
            <path
              d="M506.82 195.997C501.694 194.025 494.989 193.434 487.693 193.434H447.07V180.813H516.878V159.122H418.477V252.987H485.129C494.398 252.987 501.298 252.199 506.426 250.226C517.272 246.084 524.568 236.028 524.568 222.816C524.568 209.408 517.666 200.139 506.82 195.997ZM492.622 228.534C491.045 229.127 488.876 229.323 486.114 229.323H447.07V214.337H486.114C489.27 214.337 491.833 214.535 493.806 215.52C496.171 216.703 497.355 218.873 497.355 221.632C497.355 224.393 495.975 227.351 492.622 228.534Z"
              fill="white"
            />
            <path
              d="M604.74 159.122H562.935L524.482 252.987H556.823L562.146 239.38H605.529L611.051 252.987H643.194L604.74 159.122ZM569.245 218.674L582.852 180.813H585.021L598.628 218.674H569.245Z"
              fill="white"
            />
            <path
              d="M679.998 218.084V252.987H651.406V159.122H679.998V193.434H725.945V159.122H754.539V252.987H725.945V218.084H679.998Z"
              fill="white"
            />
            <path
              d="M799.797 217.492V252.987H771.204V159.122H799.797V194.42H807.685L842.194 159.122H878.083L832.334 204.082L879.858 252.987H841.799L807.685 217.492H799.797Z"
              fill="white"
            />
            <path
              d="M136.884 98.7815H364.839V213.426C364.839 242.816 349.161 269.974 323.71 284.671L250.862 326.736L178.014 284.671C152.562 269.974 136.884 242.816 136.884 213.426V98.7815Z"
              fill="#FFDD2D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M199.445 159.122V194.779C204.321 189.273 213.186 185.546 223.323 185.546H234.338V226.997C234.338 238.025 231.342 247.678 226.896 252.987H274.811C270.374 247.672 267.385 238.032 267.385 227.018V185.546H278.401C288.537 185.546 297.402 189.273 302.278 194.779V159.122H199.445Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_2883_1199">
              <rect width="1035.63" height="406.522" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </Stack>
  );
};

export default RegistrationPayment;
