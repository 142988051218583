import React, { useState } from "react";
import styles from "./Notes.module.css";

const iconOptions = [
  "fa-solid fa-circle-info",
  "fa-solid fa-star",
  "fa-solid fa-heart",
  "fa-solid fa-bomb",
  "fa-solid fa-bolt",
  "fa-solid fa-gift",
  "fa-solid fa-fire",
  "fa-solid fa-money-bill",
  "fa-solid fa-thumbs-up",
  "fa-solid fa-globe",
  "fa-solid fa-handshake",
  "fa-solid fa-circle",
  "fa-solid fa-flag",
  "fa-solid fa-shield",
  "fa-solid fa-rocket",
  "fa-solid fa-award",
  "fa-solid fa-trophy",
  "fa-solid fa-square-check",
  "fa-solid fa-crown",
  "fa-solid fa-medal",
  "fa-solid fa-circle-check",
  "fa-solid fa-xmark",
  "fa-regular fa-circle-xmark",
  "fa-solid fa-circle-xmark",
  "fa-solid fa-ban",
  "fa-solid fa-circle-exclamation",
];

const colorOptions = [
  "#000000",
  "#008000",
  "#2E8B57",
  "#4B0082",
  "#5F9EA0",
  "#808080",
  "#D3D3D3",
  "#90EE90",
  "#ADD8E6",
  "#1e3a78",
  "#1E90FF",
  "#FFD700",
  "#FF0000",
  "#DC143C",
  "#8B0000",
  "#F08080",
  "#FF1493",
  "#FFB6C1",
  "#D8BFD8",
  "#FF8C00",
  "#9400D3",
];

const initialData = [
  {
    time: "10:00",
    category: "Борьба",
    first: "Иванов Иван",
    second: "Петров Петр",
    third: "Сидоров Сидор",
    categoryComment: "",
    categoryNote: "",
    categoryIcon: "fa-solid fa-pen",
    categoryColor: "#1e3a78",
  },
  {
    time: "10:30",
    category: "Бег",
    first: "Алексеев Алексей",
    second: "Смирнов Сергей",
    third: "Кузнецов Николай",
    categoryComment: "",
    categoryNote: "",
    categoryIcon: "fa-solid fa-pen",
    categoryColor: "#1e3a78",
  },
  {
    time: "11:00",
    category: "Плавание",
    first: "Попов Павел",
    second: "Соколов Андрей",
    third: "Морозов Максим",
    categoryComment: "",
    categoryNote: "",
    categoryIcon: "fa-solid fa-pen",
    categoryColor: "#1e3a78",
  },
];

const Notes = () => {
  const [data, setData] = useState(initialData);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [modalText, setModalText] = useState("");
  const [isNoteMode, setIsNoteMode] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("fa-solid fa-pen");
  const [selectedColor, setSelectedColor] = useState("#1e3a78");
  const [showIcons, setShowIcons] = useState(false);
  const [showColors, setShowColors] = useState(false);

  const openModal = (entry, isNote = false) => {
    setCurrentEntry(entry);
    setModalText(
      isNote ? entry.categoryNote || "" : entry.categoryComment || ""
    );
    setSelectedIcon(entry.categoryIcon);
    setSelectedColor(entry.categoryColor);
    setIsNoteMode(isNote);
    setModalVisible(true);
  };

  const saveChanges = () => {
    if (currentEntry) {
      const updatedData = data.map((item) =>
        item === currentEntry
          ? {
              ...item,
              categoryIcon: selectedIcon,
              categoryColor: selectedColor,
              categoryNote: isNoteMode ? modalText : item.categoryNote,
              categoryComment: !isNoteMode ? modalText : item.categoryComment,
            }
          : item
      );
      setData(updatedData);
      setModalVisible(false);
    }
  };

  const deleteEntry = () => {
    if (currentEntry) {
      const updatedData = data.map((item) =>
        item === currentEntry
          ? {
              ...item,
              categoryIcon: "fa-solid fa-pen",
              categoryColor: "#1e3a78",
              categoryNote: isNoteMode ? "" : item.categoryNote,
              categoryComment: !isNoteMode ? "" : item.categoryComment,
            }
          : item
      );
      setData(updatedData);
      setModalVisible(false);
    }
  };

  return (
    <div className={styles.container}>
      {modalVisible && (
        <div
          className={styles.modalOverlay}
          onClick={() => setModalVisible(false)}
        />
      )}
      {modalVisible && (
        <div className={styles.modal}>
          <textarea
            value={modalText}
            onChange={(e) => setModalText(e.target.value)}
            placeholder={
              isNoteMode ? "Добавьте примечание..." : "Добавьте комментарий..."
            }
            className={styles.textarea}
          />
          {isNoteMode && (
            <div className={styles.controlsRow}>
              <div
                className={`${styles.iconPreview} ${showIcons ? "active" : ""}`}
                onClick={() => {
                  setShowIcons(!showIcons);
                  setShowColors(false); // Закрыть цвета
                }}
              >
                <i
                  className={selectedIcon}
                  style={{ color: selectedColor }}
                ></i>{" "}
                Иконка
              </div>
              <div
                className={`${styles.colorPreview} ${
                  showColors ? "active" : ""
                }`}
                onClick={() => {
                  setShowColors(!showColors);
                  setShowIcons(false); // Закрыть иконки
                }}
              >
                <div
                  className={styles.colorBox}
                  style={{ backgroundColor: selectedColor }}
                ></div>{" "}
                Цвет
              </div>
            </div>
          )}
          {showIcons && (
            <div className={styles.iconGrid}>
              {iconOptions.map((icon, index) => (
                <i
                  key={index}
                  className={icon}
                  onClick={() => {
                    setSelectedIcon(icon);
                    setShowIcons(false);
                  }}
                ></i>
              ))}
            </div>
          )}
          {showColors && (
            <div className={styles.colorGrid}>
              {colorOptions.map((color, index) => (
                <div
                  key={index}
                  className={`${styles.colorOption} ${
                    selectedColor === color ? styles.selectedColor : ""
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => {
                    setSelectedColor(color);
                    setShowColors(false);
                  }}
                ></div>
              ))}
            </div>
          )}
          <div className={styles.buttonGroup}>
            <button className={styles.primary} onClick={saveChanges}>
              Сохранить
            </button>
            <button
              className={styles.secondary}
              onClick={() => setModalVisible(false)}
            >
              Отмена
            </button>
            <button className={styles.secondary} onClick={deleteEntry}>
              Удалить
            </button>
          </div>
        </div>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Время</th>
            <th>Категория</th>
            <th>1 место</th>
            <th>2 место</th>
            <th>3 место</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr key={index}>
              <td className={styles.timeCell}>{entry.time}</td>
              <td className={styles.categoryCell}>
                <div className={styles.editable}>
                  <span>{entry.category}</span>
                  <i
                    className={entry.categoryIcon}
                    style={{ color: entry.categoryColor }}
                    onClick={() => openModal(entry, true)}
                    data-tooltip={entry.categoryNote || "Добавить примечание"}
                  ></i>
                  <i
                    className="fa-regular fa-message"
                    onClick={() => openModal(entry, false)}
                    data-tooltip={
                      entry.categoryComment || "Добавить комментарий"
                    }
                  ></i>
                </div>
              </td>
              <td>{entry.first}</td>
              <td>{entry.second}</td>
              <td>{entry.third}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Notes;
